var exports = {};
/**
 * Checks if a given buffer contains only correct UTF-8.
 * Ported from https://www.cl.cam.ac.uk/%7Emgk25/ucs/utf8_check.c by
 * Markus Kuhn.
 *
 * @param {Buffer} buf The buffer to check
 * @return {Boolean} `true` if `buf` contains only correct UTF-8, else `false`
 * @public
 */
function isValidUTF8(buf) {
  const len = buf.length;
  let i = 0;
  while (i < len) {
    if ((buf[i] & 128) === 0) {
      // 0xxxxxxx
      i++;
    } else if ((buf[i] & 224) === 192) {
      // 110xxxxx 10xxxxxx
      if (i + 1 === len || (buf[i + 1] & 192) !== 128 || (buf[i] & 254) === 192 // overlong
      ) {
        return false;
      }
      i += 2;
    } else if ((buf[i] & 240) === 224) {
      // 1110xxxx 10xxxxxx 10xxxxxx
      if (i + 2 >= len || (buf[i + 1] & 192) !== 128 || (buf[i + 2] & 192) !== 128 || buf[i] === 224 && (buf[i + 1] & 224) === 128 ||
      // overlong
      buf[i] === 237 && (buf[i + 1] & 224) === 160 // surrogate (U+D800 - U+DFFF)
      ) {
        return false;
      }
      i += 3;
    } else if ((buf[i] & 248) === 240) {
      // 11110xxx 10xxxxxx 10xxxxxx 10xxxxxx
      if (i + 3 >= len || (buf[i + 1] & 192) !== 128 || (buf[i + 2] & 192) !== 128 || (buf[i + 3] & 192) !== 128 || buf[i] === 240 && (buf[i + 1] & 240) === 128 ||
      // overlong
      buf[i] === 244 && buf[i + 1] > 143 || buf[i] > 244 // > U+10FFFF
      ) {
        return false;
      }
      i += 4;
    } else {
      return false;
    }
  }
  return true;
}
exports = isValidUTF8;
export default exports;